import Swal from "sweetalert2";

function _Toast() {
  return {
    success(message = 'Registro salvo.') {
      Swal.fire(message, {
        text: message,
        icon: "success",
      })
    },
    warning(message = 'Ocorreu um erro, entre em contato com o suporte.',title = 'Error') {
      Swal.fire({
        title,
        text: message,
        icon: "warning"
      });
    },
    descriptionCourse(html) {
      Swal.fire({
        title: "Descrição curso",
        text: '',
        html: `<p style="text-align:left;">${html}</p>`,
      });
    },
    imgToast(){
      Swal.fire({
        imageUrl: "https://aluno.bmsltda.com.br/img/1_y0r718mO33GhYsTIT2wvGQ.gif",
        imageHeight: 350,
        imageAlt: "A tall image",
        footer:'Aguarde',
        showConfirmButton:false,
        showCancelButton:false,
      });
    }
  }
}

export const Toast = _Toast()