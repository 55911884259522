import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { NavBar } from "../../components/NavBar";
import { extractPixValue, maskCurrency } from "../../utils/utils";
import { Toast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';

export function HomeHome () {
  const navigate = useNavigate()
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(true)

  async function getCourses () {
    const response = await api.get('/api/v1/courses')
    const currentDate = DateTime.local()

    const dataCourse = response.data.data.map((course) => {
      const startDateDiff = DateTime.fromSQL(course.startDate).diff(currentDate, 'days').days;

      return {
        ...course,
        isHideButton: startDateDiff < 0
      };
    });

    setCourses(dataCourse.filter(e => e.active === true))
    setLoading(false)
  }

  useEffect(() => {
    getCourses()
  }, [])

  return (
    <>
      <NavBar>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Nosso cursos</h1>
          </div>

          {loading && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          )}
          <div className="row">
            {!loading && courses.map((course) => {
              return (
                <div key={course.id} className="col-sm-4 mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                      <div>
                        <h6 className="m-0 font-weight-bold text-primary">{course.name}</h6>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="text-center">
                        <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{ width: 250, height: 180 }}
                          src={course.url || "img/curso-virtual-jpg.jpg"} alt="..." />
                      </div>
                      <p>{String(course.description).substring(0, 200) + '....'} <a className="text-primary pointer" onClick={() => Toast.descriptionCourse(course.description)}>Ver mais</a></p>
                      <hr />
                      <div>
                        <p>Data Inicio: <b>{DateTime.fromSQL(course.startDate).toFormat('dd/MM/yyyy')}</b></p>
                        <p>Data Término: <b>{DateTime.fromSQL(course.endDate).toFormat('dd/MM/yyyy')}</b></p>
                        <p>Valor do curso a vista: <b>{maskCurrency(course.pricingTotal)}</b></p>
                        <p>Valor do curso (parcelado): <b>{course.qtdInstallments}x de {maskCurrency(course.pricingInstallments)}</b></p>
                        <p>Valor do material Omega (á vista): <b>{maskCurrency(course.materialPriceOmega)}</b></p>
                        <p>Valor do material Omega (parcelado):  <b>{course.qtdInstallmentsMaterialOmega}x de {maskCurrency(course.pricingInstallmentsMaterialOmega)}</b></p>
                        <p>Valor da matricula: <b>{maskCurrency(extractPixValue(course.pix))}</b></p>
                      </div>
                      <div>
                        <hr />
                        {!course.isHideButton && (
                          <button className="btn btn-sm btn-primary" onClick={() => navigate(`/subscription?id=${course.id}`)}>Matricule-se</button>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </NavBar>

    </>

  )
}
