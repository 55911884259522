/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */

import { useContext } from "react"
import { GlobalContext } from "../../providers/GlobalProvider"
import { IoHome } from "react-icons/io5"
import { FaBookOpen } from "react-icons/fa"
import { GrSchedule } from "react-icons/gr"
import { GiNotebook } from "react-icons/gi"

export function NavBar ({ children }) {
  const { student } = useContext(GlobalContext)

  return (
    <div id="wrapper">
      <ul className=" navbar-nav bg-primary sidebar sidebar-dark accordion" id="accordionSidebar ">
        <a className="sidebar-brand d-flex align-items-center justify-content-center mt-4" href="/home">
          <div className="bg-white w-100" style={{ paddingTop: 5, paddingBottom: 15, borderRadius: 10 }}>
            <img width={50} src="https://cdn-icons-png.flaticon.com/512/1828/1828360.png" alt="" />
          </div>
        </a>

        <hr className="sidebar-divider mt-3" />

        <li className="nav-item active">
          <a className="nav-link" href="/home">
            <IoHome size={20} className="me-2 mb-2" />
            <span style={{ fontSize: 20, marginLeft: 10 }}>Inicio</span>
          </a>
        </li>



        <li className="nav-item">
          <a className="nav-link" href="/matricula">
            <FaBookOpen size={20} className="me-2 mb-2" />
            <span style={{ fontSize: 20, marginLeft: 10 }}>Meus Cursos</span></a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="/agenda">
            <GrSchedule size={20} className="me-2 mb-2" />
            <span style={{ fontSize: 20, marginLeft: 10 }}>Agenda</span></a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="/home">
            <FaBookOpen size={20} className="me-2 mb-2" />
            <span style={{ fontSize: 20, marginLeft: 10 }}>Nossos Cursos</span></a>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />

        <div className="sidebar-card d-none d-lg-flex">
          <img width={150} className="bg-white p-2 rounded mb-2" src="/img/omega.7041f9d3.png" alt="..." />
          <a className="w-100 btn btn-dark btn-sm" onClick={() => {
            localStorage.removeItem('token')
            setTimeout(() => {
              window.location.href = '/'
            }, 100);
          }}>Sair</a>
        </div>

      </ul>

      <div id="content-wrapper" className="d-flex flex-column">

        <div id="content" style={{ width: '100%!important' }}>

          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block"></div>

              <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="/home" id="userDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">{student.name}</span>
                  <img className="img-profile rounded-circle"
                    src="img/undraw_profile.svg" alt="" />
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown">
                  {/* <a className="dropdown-item" href="#">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Perfil
                  </a> */}
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" onClick={() => {
                    localStorage.removeItem('token')
                    setTimeout(() => {
                      window.location.href = '/'
                    }, 100);
                  }}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Sair
                  </a>
                </div>
              </li>
            </ul>

          </nav>
          {children}
        </div>

        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright &copy; Matheus M Militão {new Date().getFullYear()}</span>
            </div>
          </div>
        </footer>

      </div>
    </div>
  )
}
